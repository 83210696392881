import {Divider, Stack, Typography} from "@mui/material";
import {Types} from "aptos";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import useSignAndSubmitTransaction from "../../../api/useSignAndSubmitTransaction";
import HashButton from "../../../components/AccountAddressButton";
import LoadingModal from "../../../components/LoadingModal";
import {extractAptFromDomainName} from "../../../utils";
import {CONTRACT_ADDRESS} from "../../../utils/constant";
import ClearButton from "../../Domains/Components/DomainNameInfo/ClearButton";
import {DomainDetails} from "../../Domains/hooks/useGetDomainNameInfo";
import UpdateTargetAddressInput from "./components/UpdateTargetAddressInput";

type SettingsTabProps = {
  domain: string;
  parentDomainDetails: DomainDetails | null;
};

export default function SettingsTab({
  domain,
  parentDomainDetails,
}: SettingsTabProps) {
  const navigate = useNavigate();
  const {submitTransaction, transactionInProcess} =
    useSignAndSubmitTransaction();
  const [targetAddress, setTargetAddress] = useState<string>(
    parentDomainDetails.target_address.vec[0],
  );

  const updateTargetAddress = async (newAddress: string) => {
    const payload: Types.TransactionPayload = {
      type: "entry_function_payload",
      function: `${CONTRACT_ADDRESS}::domains::set_domain_address`,
      type_arguments: [],
      arguments: [extractAptFromDomainName(domain), newAddress],
    };
    const success = await submitTransaction(payload);
    if (success) {
      navigate("/account");
    }
  };

  return (
    <>
      <LoadingModal open={transactionInProcess} />
      <Stack
        spacing={4}
        divider={
          <Divider variant="dotted" orientation="horizontal" sx={{mb: 0}} />
        }
      >
        <Stack spacing={4} mb={3}>
          <Stack direction="row">
            <Typography variant="h6" mr={2}>
              Update Current Target Address
            </Typography>
            {targetAddress && <HashButton address={targetAddress} />}
          </Stack>
          <Divider variant="inset" orientation="horizontal" sx={{mb: 0}} />
          <Typography variant="body1">
            Set the address that the name points to
          </Typography>
          <UpdateTargetAddressInput updateTargetAddress={updateTargetAddress} />
        </Stack>
        <Stack spacing={4} mb={3}>
          <Typography variant="h6">Clear Target Address</Typography>
          <Divider variant="inset" orientation="horizontal" sx={{mb: 0}} />
          <Stack
            direction="row"
            justifyContent="space-between"
            justifyItems="center"
          >
            <Typography variant="body1">
              This will remove the address from the mapping, but retain
              ownership
            </Typography>
            <ClearButton
              domainName={domain}
              disable={true}
              callback={setTargetAddress}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
