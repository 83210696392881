import {getEventsByEventHandle, getTableItem} from "../../../api";
import {GlobalStateContext} from "../../../context/globalState/context";
import {COLLECTION_NAME, CONTRACT_ADDRESS} from "../../../utils/constant";
import {extractSubdomainName} from "../../../utils";
import {Domain} from "../../../utils/types";

type Event = {
  data: {
    id: Token;
  };
};

type Token = {
  property_version: string;
  token_data_id: {
    collection: string;
    name: string;
    creator: string;
  };
};

const getEvents = async (address: string, event: string): Promise<Event[]> => {
  try {
    const events = await getEventsByEventHandle({
      address: address,
      eventHandleStruct: "0x3::token::TokenStore",
      fieldName: event,
    });
    return events;
  } catch (e) {
    console.log(e);
  }

  return [];
};

type Temp = {
  [id: string]: any;
};

const getOwnedTokens = async (
  depositEvents: Event[],
  withdrawEvents: Event[],
): Promise<Token[]> => {
  const depositCount: Temp = {};
  const withdrawCount: Temp = {};
  const tokens: Temp = {};
  const ownedTokens: Token[] = [];

  const makeId = (elem: Event) => {
    return `${elem.data.id.token_data_id.creator}::${elem.data.id.token_data_id.collection}::${elem.data.id.token_data_id.name}::${elem.data.id.property_version}`;
  };

  for (const elem of depositEvents) {
    const id = makeId(elem);
    depositCount[id] = Number(depositCount[id] || 0) + 1;
    tokens[id] = elem.data.id;
  }
  for (const elem of withdrawEvents) {
    const id = makeId(elem);
    withdrawCount[id] = Number(withdrawCount[id] || 0) + 1;
    tokens[id] = elem.data.id;
  }

  for (const id of Object.keys(depositCount)) {
    if ((withdrawCount[id] || 0) < depositCount[id]) {
      ownedTokens.push(tokens[id]);
    }
  }
  return ownedTokens;
};

const getOwnedSubdomains = async (
  domainName: string,
  tokens: Token[],
  state: GlobalStateContext,
): Promise<Domain[]> => {
  const ownedDomains: Domain[] = [];
  for await (const tokenId of tokens) {
    if (
      COLLECTION_NAME &&
      tokenId.token_data_id.collection !== COLLECTION_NAME
    ) {
      continue;
    }
    if (
      state.collectionCreator &&
      tokenId.token_data_id.creator !== state.collectionCreator
    ) {
      continue;
    }
    // skip domain names
    if (tokenId.token_data_id.name.match(/\./g)?.length == 1) {
      continue;
    }
    // if token domain name is not the same as the domain name we search for - skip
    if (tokenId.token_data_id.name.split(".")[1] !== domainName) {
      continue;
    }

    try {
      const tokenName = extractSubdomainName(tokenId.token_data_id.name);

      const domainsTableItemRequest = {
        key_type: `${CONTRACT_ADDRESS}::domains::NameRecordKeyV1`,
        value_type: `${CONTRACT_ADDRESS}::domains::NameRecordV1`,
        key: {
          subdomain_name: {vec: [tokenName]},
          domain_name: domainName,
        },
      };

      const token = await getTableItem({
        tableHandle: state.tableHandle,
        data: domainsTableItemRequest,
      });
      if (token) {
        token["name"] = tokenId.token_data_id.name;
        ownedDomains.push(token);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return ownedDomains;
};

export const getAccountSubdomains = async (
  domainName: string,
  address: string,
  state: GlobalStateContext,
): Promise<Domain[]> => {
  const depositEvents = await getEvents(address, "deposit_events");
  const withdrawEvents = await getEvents(address, "withdraw_events");
  const tokens = await getOwnedTokens(depositEvents, withdrawEvents);
  const domains = await getOwnedSubdomains(domainName, tokens, state);
  return domains;
};
