import {useLocation, useParams} from "react-router-dom";

import {extractAptFromDomainName, isValidDomainName} from "../../utils";
import {useGlobalState} from "../../context";
import {useEffect, useState} from "react";
import {isDomainAvailable} from "../../api/isDomainAvailable";
import DomainNameRegister from "./DomainNameRegister";
import DomainNameInfo from "./DomainNameInfo";
import InvalidDomainName from "./Components/InvalidDomainName";

type DomainNameURLParams = {
  domain: string;
};

export default function DomainName(): JSX.Element {
  let {domain} = useParams() as DomainNameURLParams;
  const location = useLocation();
  const state = useGlobalState();
  const [domainAvailable, setDomainAvailable] = useState<boolean>(true);

  const domainName = extractAptFromDomainName(domain);

  useEffect(() => {
    if (state.tableHandle) {
      isDomainAvailable(domainName, state.tableHandle).then(setDomainAvailable);
    }
  }, [state, domain, location]);

  if (!isValidDomainName(domainName)) {
    return <InvalidDomainName domain={domain} />;
  }

  if (domainAvailable) {
    return <DomainNameRegister />;
  }

  return <DomainNameInfo />;
}
