import {getTableItem} from ".";
import {CONTRACT_ADDRESS} from "../utils/constant";

export const isDomainAvailable = async (
  domainName: string,
  handle: string,
): Promise<boolean> => {
  const domainsTableItemRequest = {
    key_type: `${CONTRACT_ADDRESS}::domains::NameRecordKeyV1`,
    value_type: `${CONTRACT_ADDRESS}::domains::NameRecordV1`,
    key: {
      subdomain_name: {vec: []},
      domain_name: domainName,
    },
  };

  try {
    await getTableItem({
      tableHandle: handle,
      data: domainsTableItemRequest,
    });
  } catch (e) {
    // fetchTableItem returns a 404 error if the item does not exist, which means that the the item is available, therefore return `true`
    return true;
  }
  // return `false` if the item exists, which means that the the item is not available
  return false;
};
