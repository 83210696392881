import {useWallet} from "@manahippo/aptos-wallet-adapter";
import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Navigate} from "react-router-dom";
import LoadingModal from "../../../components/LoadingModal";
import {useGlobalState} from "../../../context";
import {Domain} from "../../../utils/types";
import {getAccountSubdomains} from "../api/getAccountSubdomains";
import SubdomainsTable from "./components/SubdomainsTable";

type SubdomainsTabProps = {
  domain: string;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
};

export default function SubdomainsTab({
  domain,
  setTabValue,
}: SubdomainsTabProps) {
  const state = useGlobalState();
  const {account, connected, disconnecting} = useWallet();

  const {isLoading, data, refetch} = useQuery<Array<Domain>>(
    ["getAccountSubdomains", domain, account?.address, state],
    () => getAccountSubdomains(domain, account?.address as string, state),
    {enabled: false, refetchOnWindowFocus: false, cacheTime: 0},
  );

  useEffect(() => {
    if (state && account?.address) {
      refetch();
    }
  }, [connected, state]);

  if (disconnecting) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingModal open={isLoading} />;
  }

  if (data && data.length > 0) {
    return <SubdomainsTable subdomains={data} />;
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} mb={2}>
          <Typography variant="subtitle1">
            Subdomains that you register will show up here
          </Typography>
        </Stack>
        <Button size="large" variant="contained" onClick={() => setTabValue(2)}>
          Register Subdomain
        </Button>
      </CardContent>
    </Card>
  );
}
