import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Box, Container, Typography} from "@mui/material";
import {useWallet} from "@manahippo/aptos-wallet-adapter";
import {Navigate} from "react-router-dom";
import SearchInput from "../../../../components/SearchInput";
import GeneralTableHeaderCell from "../../../../components/GeneralTableHeaderCell";
import {Domain} from "../../../../utils/types";
import DomainTableRow from "./DomainTableRow";

type DomainsTableProps = {
  domains: Domain[];
};

export default function DomainsTable({
  domains,
}: DomainsTableProps): JSX.Element {
  const {account} = useWallet();

  if (!account) {
    return <Navigate to="/" />;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <GeneralTableHeaderCell header="Aptos Name" />
            <GeneralTableHeaderCell header="Target Address" />
            <GeneralTableHeaderCell header="Expiration" textAlignRight={true} />
            <GeneralTableHeaderCell header="Actions" textAlignRight={true} />
          </TableRow>
        </TableHead>
        <TableBody>
          {domains.map((domain) => (
            <DomainTableRow key={domain.name} domain={domain} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
