import {Box, IconButton, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

type RegistrationPeriodProps = {
  years: number;
  onAddYearsClick: () => void;
  onReduceYearsClick: () => void;
};

export default function RegistrationPeriod({
  years,
  onReduceYearsClick,
  onAddYearsClick,
}: RegistrationPeriodProps): JSX.Element {
  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <IconButton
          color="primary"
          aria-label="reduce years"
          component="label"
          onClick={onReduceYearsClick}
          sx={{pl: "0px"}}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
        <Typography variant="h5" sx={{mx: 2}}>{`${years} years`}</Typography>
        <IconButton
          color="primary"
          aria-label="add years"
          component="label"
          onClick={onAddYearsClick}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Stack>
      <Typography variant="body1" sx={{opacity: "0.5"}}>
        Registration Period
      </Typography>
    </Box>
  );
}
