import {
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useWallet,
  WalletName as HippoWalletName,
} from "@manahippo/aptos-wallet-adapter";
import {ReactComponent as PetraWalletLogo} from "../../assets/svg/aptos_wallet-provider_petra.svg";
import {ReactComponent as MartianWalletLogo} from "../../assets/svg/aptos_wallet-provider_martian.svg";
import {ReactComponent as FewchaWalletLogo} from "../../assets/svg/aptos_wallet_provider_fewcha.svg";
import {ReactComponent as PontemnWalletLogo} from "../../assets/svg/aptos_wallet-provider_pontem.svg";
import RiseWalletLogo from "../../assets/aptos_wallet-provider_rise.png";
import {grey} from "../../themes/colors/aptosColorPalette";

type WalletsModalProps = {
  handleClose: () => void;
  modalOpen: boolean;
};

const walletIconMap: Record<
  string,
  string | React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {title?: string | undefined}
  >
> = {
  Petra: PetraWalletLogo,
  Martian: MartianWalletLogo,
  Fewcha: FewchaWalletLogo,
  Pontem: PontemnWalletLogo,
  'Rise Wallet': RiseWalletLogo,
};

export type WalletName = keyof typeof walletIconMap;

export default function WalletsModal({
  handleClose,
  modalOpen,
}: WalletsModalProps): JSX.Element {
  const {wallets, select} = useWallet();

  const theme = useTheme();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: `${theme.shape.borderRadius}px`,
    boxShadow: 24,
    p: 3,
  };

  const onWalletSelect = (walletName: HippoWalletName) => {
    select(walletName);
    handleClose();
  };

  const renderWalletsList = () => {
    return wallets.map((wallet) => {
      const option = wallet.adapter;
      const icon = walletIconMap[option.name as WalletName];
      return (
        <Grid key={option.name} xs={12}>
          {wallet.readyState === "Installed" ? (
            <ListItem disablePadding sx={{}}>
              <ListItemButton
                alignItems="center"
                disableGutters
                onClick={() => onWalletSelect(option.name)}
                sx={{
                  background:
                    theme.palette.mode === "dark" ? grey[700] : grey[200],
                  padding: "1rem 3rem",
                  borderRadius: `${theme.shape.borderRadius}px`,
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <ListItemAvatar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "2rem",
                    height: "2rem",
                    minWidth: "0",
                    color: `${theme.palette.text.primary}`,
                  }}
                >
                  {typeof icon === 'string'
                    ? <Box
                        component="img"            
                        src={icon}
                        sx={{width: "100%", height: "100%"}}
                      />
                    : <SvgIcon
                        component={icon}
                        inheritViewBox
                        sx={{width: "100%", height: "100%"}}
                      />}
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  primaryTypographyProps={{
                    fontSize: 18,
                  }}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => onWalletSelect(option.name)}
                >
                  Connect
                </Button>
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem
              alignItems="center"
              sx={{
                borderRadius: `${theme.shape.borderRadius}px`,
                background:
                  theme.palette.mode === "dark" ? grey[700] : grey[200],
                padding: "1rem 3rem",
                gap: "1rem",
              }}
            >
              <ListItemAvatar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "2rem",
                  height: "2rem",
                  minWidth: "0",
                  opacity: "0.25",
                }}
              >
                  {typeof icon === 'string'
                    ? <Box
                        component="img"            
                        src={icon}
                        sx={{width: "100%", height: "100%"}}
                      />
                    : <SvgIcon
                        component={icon}
                        inheritViewBox
                        sx={{width: "100%", height: "100%"}}
                      />}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  opacity: "0.25",
                }}
                primary={option.name}
                primaryTypographyProps={{
                  fontSize: 18,
                }}
              />
              <Button
                LinkComponent={"a"}
                href={option.url}
                target="_blank"
                size="small"
              >
                Install
              </Button>
            </ListItem>
          )}
        </Grid>
      );
    });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="wallet selector modal"
      aria-describedby="select a wallet to connect"
    >
      <Box sx={style}>
        <Typography align="center" variant="h5" mb={2} py={2}>
          Connect Wallet
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          {renderWalletsList()}
        </Grid>
      </Box>
    </Modal>
  );
}
