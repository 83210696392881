import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import {useWallet} from "@manahippo/aptos-wallet-adapter";
import CopyToClipboard from "../CopyToClipboard";
import {useNavigate} from "react-router-dom";

type WalletMenuProps = {
  popoverAnchor: HTMLButtonElement | null;
  handlePopoverClose: () => void;
};

export default function WalletMenu({
  popoverAnchor,
  handlePopoverClose,
}: WalletMenuProps): JSX.Element {
  const {account, disconnect} = useWallet();
  const navigate = useNavigate();
  const popoverOpen = Boolean(popoverAnchor);
  const id = popoverOpen ? "wallet-popover" : undefined;

  const onAccountOptionClicked = () => {
    navigate("/account");
    handlePopoverClose();
  };

  return (
    <Popover
      id={id}
      open={popoverOpen}
      anchorEl={popoverAnchor}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List>
        <ListItem disablePadding>
          <CopyToClipboard
            textToCopy={(account?.address && account.address.toString()) || ""}
          >
            <ListItemButton>
              <ListItemText primary="Copy Address" />
            </ListItemButton>
          </CopyToClipboard>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={onAccountOptionClicked}>
            <ListItemText primary="Account" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => disconnect()}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
}
