import {useWallet} from "@manahippo/aptos-wallet-adapter";
import {Box, Container, Typography} from "@mui/material";
import {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {getAccountDomains} from "../api/getAccountDomains";
import DomainsTable from "./components/DomainsTable";
import {Domain} from "../../../utils/types";
import {useDocumentTitle} from "../../../hooks/setDocumentTitle";
import {useGlobalState} from "../../../context";
import {useQuery} from "react-query";
import LoadingModal from "../../../components/LoadingModal";
import SearchInput from "../../../components/SearchInput";
import NoDomainsCard from "./components/NoDomainsCard";

export default function Account(): JSX.Element {
  const state = useGlobalState();
  const {account, disconnecting} = useWallet();
  const [documentTitle, setDocumentTitle] = useDocumentTitle(
    "My Account - Aptos Names",
  );

  const {isLoading, data, refetch} = useQuery<Array<Domain>>(
    ["getAccountDomains", account?.address, state],
    () => getAccountDomains(account?.address as string, state),
    {enabled: false, refetchOnWindowFocus: false, cacheTime: 0},
  );

  useEffect(() => {
    if (state && account?.address) {
      refetch();
    }
  }, [account, state]);

  if (disconnecting) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <LoadingModal open={isLoading} />
      <Box sx={{mt: "5rem"}}>
        <SearchInput />
        <Box sx={{mt: "5rem", mb: "5rem"}}>
          <Typography variant="h4" mb={3}>
            {account
              ? "Account"
              : "Please connect a wallet to view your account"}
          </Typography>

          {data && data.length > 0 && <DomainsTable domains={data} />}

          {!isLoading && data && data.length === 0 && <NoDomainsCard />}
        </Box>
      </Box>
    </Container>
  );
}
