import {Stack, Typography} from "@mui/material";

type DomainCostProps = {
  domainName: string;
};

/*
3 characters - 80 APT
4 characters - 40 APT
5 characters - 20 APT
6+ characters - 5 APT
*/

function getDomainCost(domainName: string) {
  switch (domainName.length) {
    case 3:
      return 80;
    case 4:
      return 40;
    case 5:
      return 20;
    default:
      return 5;
  }
}

export default function DomainCost({domainName}: DomainCostProps) {
  return (
    <Stack justifyContent="center" pt={1}>
      <Typography variant="h5">{getDomainCost(domainName)} APT</Typography>
      <Typography variant="body1" sx={{opacity: "0.5"}}>
        Domain Cost
      </Typography>
    </Stack>
  );
}
