import * as RRD from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {Button, Link, Typography} from "@mui/material";
import {Fragment} from "react";
import {extractAptFromDomainName, renderTimestamp} from "../../../../utils";
import {Domain} from "../../../../utils/types";
import AccountAddressButton from "../../../../components/AccountAddressButton";

type AccountTableRowProps = {
  domain: Domain;
};

export default function DomainTableRow({
  domain,
}: AccountTableRowProps): JSX.Element {
  const navigate = RRD.useNavigate();
  const address = domain.target_address.vec[0];

  const onDomainNameClick = (domainName: string) => {
    const name = extractAptFromDomainName(domainName);
    navigate(`/name/${name}`);
  };

  const handleManageClick = (domainName: string) => {
    const name = extractAptFromDomainName(domainName);
    navigate(`${name}/manage`);
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Typography
            textTransform="none"
            variant="body1"
            component={Link}
            sx={{cursor: "pointer"}}
            onClick={() => onDomainNameClick(domain.name)}
          >
            {domain.name.toLowerCase()}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {address ? <AccountAddressButton address={address} /> : "-"}
        </TableCell>
        <TableCell align="right">
          {renderTimestamp(domain.expiration_time_sec)}
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            onClick={() => handleManageClick(domain.name)}
          >
            Manage
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
